import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "gm",

  resources: {
    en: {
      translation: {
        qualified_results: "Over 430 qualified results",
        Transform_Heading: "Transform your ❤️ health with us",
        Personalized: "Personalized nutrition coaching",
        Welcome:
          "Welcome to KI-Nutritem – your expert for personalized nutrition and health optimization.",
        Certified:
          "Our certified nutritionists provide you with customized plans and ongoing support on your weight loss journey. Using our advanced LLM model aligned with Mediterranean Diet, Hormone Balance, Microbiome Optimization and Fitness, we deliver precise, evidence-based recommendations. Our AI-powered application analyzes your individual needs and creates personalized nutritional strategies to take your health to a new level. Start your transformation today and experience the precision and efficiency of data-driven nutrition coaching.",
        lets_go: "Let's go",
        choose_individual: "Choose individual program",
        How_it_works: "How it works",
        get_started:
          "Get started easily and effectively on the path to better health and nutrition with our step-by-step instructions. We make it easy to get started and help you quickly navigate our platform and use the resources you need to achieve your health goals. This is how it works:",
        here_work: "Here's how it works",
        Features: "Our focus - your benefits",
        wellness:
          "Welcome to the features section of Nutritionist – your one-stop partner for personalized nutrition and wellness.",
        personalized: "Personalized nutrition plans",
        personalized_p:
          "Experience tailored nutritional strategies optimized by our advanced AI technology. Our application only analyzes anonymized data such as your health, activity level and dietary habits and does not collect any personal information. Get precise recommendations and adapt your nutrition plan individually. Through continuous updates and intelligent learning processes, your plan is regularly optimized so that you always make the best progress - in line with the highest data protection standards.",
        guidance: "Guidance from certified nutritionists",
        guidance_p:
          "Our team of experienced and certified nutritionists will provide you with professional advice and support throughout your entire journey. We'll answer your questions, address your concerns, and keep you motivated as you pursue your goals.",
        food: "Food tracking and analysis",
        food_p:
          "Easily track your food intake with our easy-to-use app. Our nutritionists analyze your diet and eating habits to gain insights, help you identify opportunities for improvement and provide personalized recommendations.",
        meal: "Meal planning and recipes",
        meal_p:
          "Access an extensive collection of delicious and healthy recipes tailored specifically to your dietary needs. Our advanced AI technology helps you do this by optimizing recipes and meal plans based on your preferences and goals. Additionally, our nutritionists create personalized meal plans complemented by AI-driven recommendations to help you stay on track and enjoy nutritious meals. This means you benefit from an intelligent combination of expert knowledge and state-of-the-art technology.",
        lifestyle: "Lifestyle and behavior coaching",
        lifestyle_p:
          "Achieving lasting results requires more than just a diet plan. Our nutritionists will work with you to develop healthy habits, address emotional eating, and provide strategies for overcoming obstacles along the way.",
        workshops: "Nutritional education and workshops",
        workshops_p:
          "Expand your nutrition knowledge with informative articles and interactive workshops delivered through our AI-powered app. The intelligent platform provides you with tailored learning content and resources based on your individual needs. Our app helps you make informed decisions and gives you the tools you need to achieve long-term success and healthy habits.",
        blogs_h: "Our blogs",
        blogs_p:
          "Our blog is a treasure trove of informative and engaging articles written by our team of nutritionists, dietitians and wellness experts. This is what you can expect from our blog.",
        read_less: "Read Less",
        read_more: "Read More",
        practice_h: "More from practice",
        practice_p: "Be inspired by the success stories of our users!",
        feedback_p:
          "Many have already shared their progress with our app. You too can share your experiences to motivate others and support the further development of our technology. Your feedback helps us continually improve our app and expand scientific knowledge.",
        choose_gender: "Choose Your Gender?",
        select_goal: "Select Your Goal",
        select_age: "Select Your Age Group",
        select_age_p:
          "Slide to Your Age - Because every age has its own wisdom",
        next_btn: "Next",
        select_height: "Select Your Height",
        select_height_p:
          "Slide to Your Height – Because every body size has its own balance",
        select_body_type: "Select Your Body Type",
        slim: "Slim",
        average: "Average",
        heavy: "Heavy",
        lose_weight: "Boost Performance",
        gain_muscle: "Weight Management",
        get_shredded: "Enhance Well-being",
        specific_goal: "Select Your Specific Goal",
        slim_body: "Strength Training",
        slim_shredded_body: "Endurance",
        Mental_Performance: "Mental Performance",
        athlete: "Lose Weight",
        hero: "Gain Weight",
        bodybuilder: "Maintain Weight",
        beach_body: "Boost Vitality",
        workout_body: "Eat Balanced Meals",
        cross_fit_body: "Increase Physical Activity",
        promote_recovery: "Promote Recovery",
        back: "Back",
        stick: "Do you stick to one of these diets?",
        vegetarian: "Vegetarian",
        vegan: "Vegan",
        keto: "Keto",
        mediterranean: "Mediterranean",
        none: "None of the above",
        sugary_food: "How often do you eat or drink sugary foods or drinks?",
        not_often: "Not often. I don't like sweets that much.",
        three_times: "3-5 times per week",
        every_day: "Almost every day",
        every_day_4: "Several times a day",
        water_intake: "How much water do you drink every day?",
        coffee: "Coffee or tea only",
        two_glasses: "Less than 2 glasses",
        six_glasses: "2-6 glasses",
        seven: "7-10 glasses",
        ten_glasses: "More than 10 glasses",
        medications:
          "Which of the following medications are you currently taking or have you taken in the last three months?",
        current_weight:
          "What is your current weight and how much would you like to weight?",
        select_unit: "Select unit",
        current_weight_lb: "Current weight",
        target_weight: "Target weight",
        fitness_level: "What is your fitness level?",
        fitness_level_p:
          "Choose on a scale from 1 to 10, where 1 stands for beginner and 10 for a very advanced fitness level. Use the slider to determine your current level.",
        fat_level: "Choose Your Body Fat Level",
        meal_plan: "Let Us Create a Meal Plan Based on Your Preferences",
        veggies: "Veggies",
        grains: "Grains",
        ingredients: "Ingredients",
        meat_fish: "Meat & Fish",
        fruits: "Fruits",
        allergic_h: "Select What Are You Allergic To",
        support_text: "Do you need help...",
        weight_loss: "for the desired weight loss?",
        digestive: "for digestive problems and intolerances?",
        performance: "in optimizing your physical and mental performance?",
        stabilize: "stabilize your immune and hormonal system?",
        evaluation:
          "Take the first step now and complete the nutricheck. You will receive your free evaluation in 5-10 minutes.",
        carefully:
          "Read the following questions carefully and answer them with a yes or no.",
        lets_get_started: "Let's get started",
        Broccoli: "Broccoli",
        Cauliflower: "Cauliflower",
        Onion: "Onion",
        Bell_Pepper: "Bell Pepper",
        Eggplant: "Eggplant",
        Cabbage: "Cabbage",
        Asparagus: "Asparagus",
        Spinach: "Spinach",
        Cucumber: "Cucumber",
        Tomato: "Tomato",
        Rice: "Rice",
        Quinoa: "Quinoa",
        Couscous: "Couscous",
        Buckwheat: "Buckwheat",
        Millet_Groats: "Millet Groats",
        Amaranth: "Amaranth",
        Semoline: "Semoline",
        Cornmeal: "Cornmeal",
        Bulgur: "Bulgur",
        Avocado: "Avocado",
        Beans: "Beans",
        Eggs: "Eggs",
        Mushrooms: "Mushrooms",
        Cottage_cheese: "Cottage cheese",
        Milk: "Milk",
        Tofu: "Tofu",
        Hummus: "Hummus",
        Plant_milk: "Plant milk",
        Protein_powder: "Protein powder",
        Vegan_protein_powder: "Vegan protein powder",
        Turkey: "Turkey",
        Beef: "Beef",
        Chicken: "Chicken",
        Seafood: "Seafood",
        Pork: "Pork",
        Fish: "Fish",
        Citrus: "Citrus",
        Apple: "Apple",
        Pear: "Pear",
        Kiwi: "Kiwi",
        Bananas: "Bananas",
        Persimmon: "Persimmon",
        Peach: "Peach",
        Berries: "Berries",
        Grape: "Grape",
        Pomegranate: "Pomegranate",
        antibiotics: "Antibiotics < 3 months",
        antibiotics_three: "Antibiotics > 3 months",
        Chemotherapy: "Chemotherapy",
        proton_pump: "Proton pump inhibitors (PPI)",
        laxatives: "Laxatives (laxatives)",
        immunsuppressiva: "Immunosuppressants",
        home: "Home",
        offers: "Offers",
        references: "References",
        about_us: "About us",
        point_one: "Step: Fill out the comprehensive questionnaire truthfully.",
        point_two:
          "Step: Receive the AI-generated analysis report with your individual recommendations.",
        point_three:
          "Step: Implement the simple recommendations that are suitable for everyday use. If you can contact us for further advice and laboratory analyses. feel free to contact us.",
        two_glasses_sec: "up to 0.5 l / 17 oz",
        six_glasses_sec: "0.5-1.5 l / 17-50 oz",
        seven_sec: "1.5-2.5 l / 50-85 oz",
        ten_glasses_sec: "more than 2.5 l / 85 oz",
        how_many_fruits_vegetables: "How many fruits do you eat a day?",
        how_many_vegetables: "How many vegetables do you eat a day?",
        more_than_3: "More than 3",
        how_often_consume_starch:
          "How often per day do you consume starch sources such as potatoes, rice, bread, pasta, cereal flakes, etc.?",
        how_many_servings_wholegrain:
          "How many servings of the starch suppliers consist of wholemeal flour or the whole grain?",
        how_many_portions_nuts_seeds_kernels_per_week:
          "How many portions of nuts, seeds and kernels do you eat per week?",
        how_many_hours_sleep_per_night:
          "How many hours do you sleep on average per night?",
        portion: "1 portion = 1 handful",
        select_work_percentage: "How many hours do you work per week?",
        disease_head: "Have you been diagnosed with one or more diseases?",
        digestive_track: "Digestive tract",
        digestive_Krebsnn: "Cancer",
        airways_Krebsatem: "Cancer",
        skin_newdisease: "Cancer",
        Ulcerative: "Ulcerative colitis/Crohn's disease",
        Diarrhea: "Diarrhea (chronic)",
        Diverticulosis: "Diverticulosis",
        Gastritis: "Gastritis",
        Constipation: "Constipation",
        Flatulence: "Flatulence",
        Airways: "Airways",
        bronchial: "Seasonal bronchial asthma",
        ganzjährig: "Asthma all year round",
        Chronic: "Chronic bronchitis",
        Skin: "Skin, hair, joints, bones",
        Akne: "Acne",
        Eczema: "Eczema",
        Hair_loss: "Hair loss",
        Neurodermatitis: "Neurodermatitis",
        psoriasis: "psoriasis",
        Rheumatoid_arthritis: "Rheumatoid arthritis",
        osteoporosis: "osteoporosis",
        Psyche: "Psyche, nervous system",
        ADHD: "ADHD",
        Anxiety: "Anxiety disorder",
        Autistic: "Autistic spectrum",
        Depression: "Depression",
        Headaches: "Headaches/migraines",
        Alzheimer: "M. Alzheimer's disease",
        Parkinson: "M. Parkinson's disease",
        Fatigue: "Fatigue/exhaustion, chronic",
        Multiple: "Multiple sclerosis",
        Metabolism: "Metabolism, cardiovascular system",
        Diabetes: "Diabetes mellitus, type 1 or 2",
        high_blood_pressure: "High blood pressure",
        low_blood_pressure: "Low blood pressure",
        Overweight: "Overweight",
        Elevated_blood: "Elevated blood",
        Vascular_diseases: "Vascular diseases (e.g. arteriosclerosis)",
        Hypothyroidism: "Hypothyroidism",
        Food_intolerances: "Food intolerances",
        Fructose: "Fructose intolerance",
        Gluten_intolerance: "Gluten intolerance/coeliac disease",
        Lactose_intolerance: "Lactose intolerance",
        IgG_mediated: "IgG-mediated intolerances",
        IgE_mediated: "IgE-mediated allergies",
        Histamine: "Histamine",
        FODMAPs: "FODMAPs",
        Food_allergies: "Food Allergies",
        Peanuts: "Peanuts",
        Celery: "Celery",
        Crustaceans: "Crustaceans such as crabs, shrimps etc.",
        Lupin: "Lupin",
        hazelnuts: "Nuts such as almonds, hazelnuts etc.",
        Sulphur: "Sulphur dioxide/sulphides such as dried fruit",
        mustard: "mustard",
        sesame: "sesame",
        soya: "soya",
        mussels: "Molluscs such as mussels",
        None_of_the_above: "None of the above",
        Alkaline_fasting: "Alkaline fasting",
        Low_Carb: "Low-Carb",
        FODMAP: "FODMAP",
        Intermittent: "Intermittent fasting",
        Mixed_diet: "Mixed diet (omnivore)",
        Wholefood: "Wholefood diet",
        Flexitarians: "Flexitarians",
        vegetarian_options: "Which of these vegetarian diets applies to you?",
        Pescetarisch: "Pescetarian",
        Lakto_Vegetarisch: "Lacto-Vegetarian",
        Ovo_Vegetarisch: "Ovo-Vegetarian",
        Ovo_Lakto_Vegetarisch: "Ovo-Lacto-Vegetarian",
        drink_alcohol: "How often do you drink alcohol per week?",
        Nie: "Never",
        _2_Mal_pro_Woche: "1-2 Glasses",
        _4_Mal_pro_Woche: "2-4 Glasses",
        _6_Mal_pro_Woche: "4-6 Glasses",
        Jeden_Tag: "Every day",
        alcohol_type: "What kind of alcohol do you drink?",
        Wein: "Wine",
        Beer: "Beer",
        Spirits: "Spirits",
        Cocktails: "Cocktails",
        how_much_alcohol: "How much alcohol do you drink?",
        _2_servings: "1-2 servings",
        _3_servings: "2-3 servings",
        _4_servings: "3-4 servings",
        More: "More than 4 portions",
        handvoll:
          "1 Portion = 1 Handful e.g. Berries, 2-3 Apricots, 1-2 Mandarins",
        handvoll_veg: "1 Portion = 1 Handful",
        reference_text:
          "1 portion = 1 glass of wine, 3 liters of beer or a glass of schnapps",
        pay_now: "Pay Now and Get Your Plan",
        enter_details: "Enter Your Card Details",
        total: "Total",
        pay_now_btn: "Pay Now",
        guaranteed:
          "Guaranteed to be safe & secure, ensuring that all transactions are protected with the highest level of security.",
        card_number: "Card Number",
        expiration: "Expiration Date",
        coupon: "Coupon Code",
        payment_head: "Your Payment Was Successful – Thank You!",
        payment_para:
          "Thank you for completing your payment! We truly appreciate your trust in us. A detailed report will be sent directly to your email, providing a comprehensive summary of your results. If you have any questions or need further assistance, please don’t hesitate to reach out. We’re here to support you every step of the way. Thank you once again, and we look forward to serving you!",
        payment_btn: "Back to Home",
        please_select: "Please select at least one preference.",
        which_oils:
          "Which oils or fats do you need to prepare your meals and how much?",
        cold_dishes:
          "For cold dishes, e.g. salad dressing; for hot dishes, e.g. for sautéing or as a fat spread",
        None: "None",
        Butter: "Butter",
        Margarine: "Margarine",
        coconut_oil: "Other (e.g., virgin coconut oil)",
        Rapeseed_Oil: "Rapeseed Oil",
        Olive_Oil: "Olive Oil",
        Walnut_Oil: "Walnut Oil",
        Flaxseed_Oil: "Flaxseed Oil",
        Hemp_Oil: "Hemp Oil",
        Wheat_Germ: "Wheat Germ Oil",
        Soybean: "Soybean Oil",
        sunflower: "Other (e.g., sunflower oil)",
        Cooking_Butter: "Cooking Butter",
        Coconut_Oil: "Coconut Oil",
        Sunflower_Oil: "Sunflower Oil",
        Peanut_Oil: "Peanut Oil",
        Other: "Other",
        Frying_Oils: "Frying Oils (e.g., HOLL rapeseed or sunflower oil)",
        Streichfett: "spreadable fat",
        kalte: "Cold Kitchen (e.g., Salad Dressings)",
        warme: "Hot Kitchen (e.g., Frying, Steaming)",
        Frittieren: "For Deep Frying",
        processing_payment: "Processing Payment",
        Current_Weight: "Enter Current Weight",
        Target_Weight: "Enter Target Weight",
        Enter_Email: "Enter Your Email",
        invalid_promotion_code:
          "The promotion code you entered is invalid or inactive.",
        payment_error_generic:
          "An error occurred while processing your payment.",
        payment_details_error:
          "Failed to retrieve payment details. Please try again.",
        loading: "Loading...",
        select_options: "Select",
        no_allergies: "No Allergies",
        Blähungen: "Flatulence",
        ergänzen: "Irritable bowel syndrome supplement",
        Rapsl: "HOLL Rapeseed oil",
        Sonnenblumenl: "HO sunflower oil",
        harmonal: "Hormonal Dysfunctions",
        pms: "PMS",
        Menopause: "Menopause",
        zero_portions: "0 portion",
        one_to_two_portions: "1-2 portions",
        three_to_four_portions: "3-4 portions",
        five_or_more_portions: "5 or more portions",
        how_often_eat_meat: "How often do you eat meat per week?",
        Like: "Like",
        Dislike: "Dislike",
        meal_plan_dislike:
          "What foods do you like to eat? Is there anything you don't like?",
        offers: "Offers",
        references: "Refferences",
      },
    },

    gm: {
      translation: {
        qualified_results: "Über 430 qualifizierte Ergebnisse",
        Transform_Heading: "Verwandele deine ❤️ Gesundheit mit",
        Personalized: "Personalisierte KI-Ernährungsberatung",
        Welcome:
          "Wissenschaftlich basierend, mit jahrelanger Erfahrung und gesundem Menschenverstand ergänzt!",
        Certified:
          "Unser Team aus Ernährungsberater/innen und Bewegungsspezialisten bieten dir maßgeschneiderte Pläne und kontinuierliche Unterstützung auf deinem Weg. Durch den Einsatz unserer KI-Technologie, welche auf die traditionelle mediterrane Ernährung, Hormonbalance, Mikrobiom-Optimierung und Fitness abgestimmt ist, liefern wir präzise, evidenzbasierte Empfehlungen, welche auf dich abgestimmt sind. Unsere KI-gestützte Applikation analysiert deine individuellen Bedürfnisse und erstellt personalisierte Ernährungsstrategien, um deine Gesundheit auf ein neues Level zu heben. Starte noch heute mit deinem nutricheck!",
        lets_go: "Los gehts",
        choose_individual: "Individuelles Programm wählen",
        How_it_works: "Wie funktioniert es?",
        get_started:
          "Starte einfach und schnell mit unserer 3-Schritt-Anleitung zu deinem persönlichen Analysebericht. Wir machen dir den Einstieg leicht und helfen dir, dich schnell auf unserer Plattform zurechtzufinden und die Ressourcen zu nutzen, die du benötigst, um deine Gesundheitsziele zu erreichen. So funktioniert es:",
        here_work: "So funktioniert's",
        Features: "Unsere Schwerpunkte – deine Vorteile",
        wellness:
          "Willkommen im Feature-Bereich von Nutritionist – Ihrem umfassenden Partner für personalisierte Ernährung und Wellness.",
        personalized: "Personalisierte Ernährungspläne",
        personalized_p:
          "Erhalte maßgeschneiderte Ernährungsstrategien, die durch unsere fortschrittliche KI-Technologie optimiert werden. Unsere Anwendung analysiert ausschließlich anonymisierte Daten wie deinen Gesundheitszustand, dein Aktivitätsniveau und deine Ernährungsgewohnheiten und erhebt keine persönlichen Informationen. Lass dir präzise Empfehlungen erstellen und passe deinen Ernährungsplan individuell an. Durch kontinuierliche Updates und intelligente Lernprozesse wird dein Plan regelmäßig optimiert, damit du stets den besten Fortschritt erzielst – ganz im Einklang mit höchsten Datenschutzstandards.",
        guidance: "Unterstützung von den Profis",
        guidance_p:
          "Unser Team besteht ausschliesslich aus erfahrenen und studierten Ernährungsberater/innen BSc FH und steht dir während deiner gesamten Reise mit professioneller Beratung und Unterstützung zur Seite. Dabei geht es weit mehr als nur um Ernährungspläne: Wir berücksichtigen deine individuelle Ausgangslage und holen dich dort ab wo du aktuell stehst. Wir werden mit dir zusammen an deinen Gewohnheiten arbeiten, bei Bedarf Strategien gegen emotionales Essen entwickeln und Stolpersteine aus dem Weg räumen.",
        food: "Lebensmittel-Tracking und Rezeptdatenbank mittels App",
        food_p:
          "Verfolge schnell und einfach dein Essen mit unserer benutzerfreundlichen App. Unsere Ernährungsberaterinnen analysieren deine Ernährung und Essgewohnheiten und helfen dir, Verbesserungsmöglichkeiten zu erkennen und umzusetzen. Greife auf eine umfangreiche Sammlung köstlicher und gesunder Rezepte zu, die speziell auf deine Ernährungsbedürfnisse abgestimmt sind. Unsere fortschrittliche KI-Technologie unterstützt dich dabei, indem sie Rezepte und Speisepläne basierend auf deinen Vorlieben und Zielen optimiert.",
        meal: "Essensplanung und Rezepte",
        meal_p:
          "Greife auf eine umfangreiche Sammlung köstlicher und gesunder Rezepte zu, die speziell auf Deine Ernährungsbedürfnisse abgestimmt sind. Unsere fortschrittliche KI-Technologie unterstützt Dich dabei, indem sie Rezepte und Speisepläne basierend auf Deinen Vorlieben und Zielen optimiert. Zusätzlich erstellen unsere Ernährungsberater personalisierte Speisepläne, die durch KI-gesteuerte Empfehlungen ergänzt werden, um Dir zu helfen, den Überblick zu behalten und nahrhafte Mahlzeiten zu genießen. So profitierst Du von einer intelligenten Kombination aus Expertenwissen und modernster Technologie.",
        lifestyle: "Lebensstil- und Verhaltenscoaching",
        lifestyle_p:
          "Um nachhaltige Ergebnisse zu erzielen, ist mehr als nur ein Diätplan erforderlich. Unsere Ernährungsberater werden mit Dir zusammenarbeiten, um gesunde Gewohnheiten zu entwickeln, emotionales Essen anzugehen und Strategien zur Überwindung von Hindernissen auf dem Weg bereitzustellen.",
        workshops: "Ernährungswissen und Workshops",
        workshops_p:
          "Erweitere dein Ernährungswissen mit informativen Artikeln und interaktiven Workshops, die durch unsere KI-gestützte App bereitgestellt werden. Die intelligente Plattform liefert dir maßgeschneiderte Lerninhalte und Ressourcen, die auf deinen individuellen Bedürfnissen basieren. Unsere App unterstützt dich dabei, fundierte Entscheidungen zu treffen und bietet dir die Werkzeuge, die du benötigst, um langfristigen Erfolg und gesunde Gewohnheiten umzusetzen.",
        blogs_h: "Unser Blog",
        blogs_p:
          "Unser Blog ist eine Fundgrube informativer und ansprechender Artikel, die von unserem Team verfasst wurden.",
        read_less: "Weniger...",
        read_more: "Mehr...",
        practice_h: "Mehr aus der Praxis",
        practice_p:
          "Lass Dich von den Erfolgsgeschichten unserer Nutzer inspirieren!",
        feedback_p:
          "Viele haben bereits ihre Fortschritte mit unserer App geteilt. Teile auch Du Deine Erfahrungen, um andere zu motivieren und die Weiterentwicklung unserer Technologie zu unterstützen. Dein Feedback trägt dazu bei, unsere App kontinuierlich zu verbessern und wissenschaftliche Erkenntnisse zu erweitern.",
        choose_gender: "Wähle dein Geschlecht ?",
        select_age: "Gebe deine Altersstufe ein",
        select_age_p:
          "Slide to Your Age – Weil jedes Alter seine eigene Weisheit hat",
        next_btn: "Weiter",
        select_goal: "Wähle dein Ziel",
        select_height: "Stelle deine Körpergröße ein",
        select_height_p:
          "Slide to Your Height - Weil jede Körpergröße ihre eigene Balance hat",
        select_body_type: "Wähle dein Körpertyp",
        slim: "Schlank",
        average: "Durchschnittlich",
        heavy: "Schwer",
        lose_weight: "Performance steigern",
        gain_muscle: "Gewichtsmagement",
        get_shredded: "Wohlbefinden steigern",
        specific_goal: "Wähle dein spezifisches Ziel",
        slim_body: "Kraftsport",
        slim_shredded_body: "Ausdauer",
        Mental_Performance: "mentale Leistungsfähigkeit",
        athlete: "Abnehmen",
        hero: "Zunehmen",
        bodybuilder: "Gewicht halten",
        beach_body: "Vitalität steigern",
        workout_body: "Ausgewogen essen",
        cross_fit_body: "Mehr bewegen",
        promote_recovery: "Regeneration fördern",
        back: "Zurück",
        stick: "Hältst du dich an eine dieser Ernährungsformen?",
        vegetarian: "Vegetarisch",
        vegan: "Vegan",
        keto: "Keto",
        mediterranean: "Mediterrane Diät",
        none: "Keiner der oben genannten Punkte",
        sugary_food:
          "Wie oft isst oder trinkst du zuckerhaltige Lebensmittel oder Getränke?",
        not_often: "Nicht oft. Ich stehe nicht so auf Süßes.",
        three_times: "3-5 Mal pro Woche",
        every_day: "jeden Tag",
        every_day_4: "Mehrmals täglich",
        water_intake:
          "Wie viel Leitungswasser, Mineralwasser oder ungesüssten Tee trinkst du täglich",
        coffee: "Nur Kaffee oder Tee",
        two_glasses: "Weniger als 2 Gläser",
        six_glasses: "2-6 Gläser",
        seven: "7-10 Gläser",
        ten_glasses: "Mehr als 10 Gläser",
        medications:
          "Welche der folgenden Medikamente nimmst Du derzeit ein oder hast Du in den letzten drei Monaten eingenommen?",
        current_weight:
          "Stelle dein aktuelles Gewicht sowie dein Zielgewicht ein.",
        select_unit: "Einheit auswählen",
        current_weight_lb: "Aktuelles Gewicht",
        target_weight: "Zielgewicht",
        fitness_level: "Wie schätzt du dein aktuelles Leistungslevel ein?",
        fitness_level_p:
          "Wähle auf einer Skala von 1 bis 10, wobei 1 für Anfänger steht und 10 für ein sehr fortgeschrittenes Fitnessniveau. Nutze den Schieberegler, um deinen aktuellen Stand zu bestimmen.",
        fat_level: "Wie hoch ist dein Körperfettanteil",
        meal_plan: "Lass uns einen Essensplan nach deinen Vorlieben erstellen:",
        veggies: "Gemüsesorten",
        grains: "Getreidearten",
        ingredients: "Inhaltsstoffe",
        meat_fish: "Fleisch & Fisch",
        fruits: "Früchte",
        allergic_h: "Wähle aus, gegen was du allergisch bist",
        support_text: "Brauchst du Unterstützung…",
        weight_loss: "bei einer gewünschten Gewichtsabnahme?",
        digestive: "bei Verdauungsproblemen und Unverträglichkeiten?",
        performance:
          "bei der Optimierung deiner physischen und mentalen Leistungsfähigkeit?",
        stabilize: "beim Stabilisieren deines Immun- und Hormonsystems?",
        evaluation:
          "Mache jetzt den ersten Schritt und absolviere den nutricheck. Du bekommst deine kostenlose Auswertung in 5-10 Minuten.",
        carefully:
          "Lese die folgenden Fragen aufmerksam durch und beantworte diese mit einem Ja oder Nein.",
        lets_get_started: "Lass uns beginnen",
        Broccoli: "Brokkoli",
        Cauliflower: "Blumenkohl",
        Onion: "Zwiebel",
        Bell_Pepper: "Paprika",
        Eggplant: "Aubergine",
        Cabbage: "Kohl",
        Asparagus: "Spargel",
        Spinach: "Spinat",
        Cucumber: "Gurke",
        Tomato: "Tomate",
        Rice: "Reis",
        Quinoa: "Quinoa",
        Couscous: "Couscous",
        Buckwheat: "Buchweizen",
        Millet_Groats: "Hirse",
        Amaranth: "Amaranth",
        Semoline: "Grieß",
        Cornmeal: "Maismehl",
        Bulgur: "Bulgur",
        Avocado: "Avocado",
        Beans: "Bohnen",
        Eggs: "Eier",
        Mushrooms: "Pilze",
        Cottage_cheese: "Hüttenkäse",
        Milk: "Milch",
        Tofu: "Tofu",
        Hummus: "Hummus",
        Plant_milk: "Pflanzenmilch",
        Protein_powder: "Eiweißpulver",
        Vegan_protein_powder: "Veganer Eiweißpulver",
        Turkey: "Pute",
        Beef: "Rindfleisch",
        Chicken: "Hähnchen",
        Seafood: "Meeresfrüchte",
        Pork: "Schweinefleisch",
        Fish: "Fisch",
        Citrus: "Zitrusfrüchte",
        Apple: "Apfel",
        Pear: "Birne",
        Kiwi: "Kiwi",
        Bananas: "Bananen",
        Persimmon: "Kaki",
        Peach: "Pfirsich",
        Berries: "Beeren",
        Grape: "Traube",
        Pomegranate: "Granatapfel",
        antibiotics: "Antibiotika < 3 Monate",
        antibiotics_three: "Antibiotika > 3 Monate",
        Chemotherapy: "Chemotherapie",
        proton_pump: "Protonenpumpenhemmer (PPI)",
        laxatives: "Laxantien (Abführmittel)",
        immunsuppressiva: "Immunsuppressiva",
        home: "Home",
        offers: "Angebote",
        references: "Referenzen",
        about_us: "Über uns",
        point_one:
          "Schritt: Fülle den umfassenden Fragekatalog wahrheitsgetreu aus.",
        point_two:
          "Schritt: Erhalte den KI-generierten Analysebericht mit deinen individuellen Empfehlungen.",
        point_three:
          "Schritt: Setze die einfachen und alltagstauglichen Empfehlungen um. Bei Bedarf kannst du dich für weiterführende Beratungen und Laboranalysen gerne bei uns melden.",
        two_glasses_sec: "bis zu 0,5 l / 17 oz",
        six_glasses_sec: "0,5-1,5 l / 17-50 oz",
        seven_sec: "1,5-2,5 l / 50-85 oz",
        ten_glasses_sec: "mehr als 2,5 l / 85 oz",
        how_many_fruits_vegetables: "Wie viele Früchte isst du pro Tag?",
        how_many_vegetables: "Wie viel Gemüse isst du pro Tag?",
        more_than_3: "Mehr als 3",
        how_often_consume_starch:
          "Wie häufig pro Tag konsumierst du Stärkelieferanten wie Kartoffeln, Reis, Brot, Pasta, Getreideflocken etc.?",
        how_many_servings_wholegrain:
          "Wie viele Portionen von den Stärkelieferanten bestehen aus Vollkornmehl resp. aus dem ganzen Korn?",
        how_many_portions_nuts_seeds_kernels_per_week:
          "Wie viele Portionen Nüsse, Samen und Kerne isst du pro Woche?",
        how_many_hours_sleep_per_night:
          "Wie viele Stunden schläfst du im Durchschnitt pro Nacht?",
        portion: "1 Portion = 1 Handvoll",
        select_work_percentage: "Wie viele Stunden arbeitest du in der Woche?",
        disease_head:
          " Wurde bei dir eine oder mehrere Krankheiten diagnostiziert resp. hast du Beschwerden?",
        Blähungen: "Blähungen",
        ergänzen: "Reizdarm",
        digestive_track: "Verdauungstrakt",
        digestive_Krebsnn: "Krebs",
        airways_Krebsatem: "Krebs",
        skin_newdisease: "Krebs",
        Ulcerative: "Colitis ulcerosa/Morbus Crohn",
        Diarrhea: "Durchfall (chronisch)",
        Diverticulosis: "Divertikulose",
        Gastritis: "Gastritis",
        Constipation: "Verstopfung",
        Flatulence: "Flatulenz",
        Airways: "Atemwege",
        bronchial: "Asthma bronchiale saisonal",
        ganzjährig: "Asthma ganzjährig",
        Chronic: "Bronchitis chronisch",
        Skin: "Haut, Haare, Gelenke, Knochen",
        Akne: "Akne",
        Eczema: "Ekzeme",
        Hair_loss: "Haarausfall",
        Neurodermatitis: "Neurodermitits",
        psoriasis: "Psoriasis",
        Rheumatoid_arthritis: "Rheumatoide Arthritis",
        osteoporosis: "Osteoporose",
        Psyche: "Psyche, Nervensystem",
        ADHD: "ADHS",
        Anxiety: "Angststörung",
        Autistic: "Autistischer Formenkreis",
        Depression: "Depression",
        Headaches: "Kopfschmerzen/Migräne",
        Alzheimer: "M. Alzheimer",
        Parkinson: "M. Parkinson",
        Fatigue: "Müdigkeit/Erschöpfung, chronisch",
        Multiple: "Multiple Sklerose",
        Metabolism: "Stoffwechsel, Herz-Kreislauf",
        Diabetes: "Diabetes Mellitus, Typ 1 oder 2",
        high_blood_pressure: "Bluthochdruck",
        low_blood_pressure: "Tiefer Blutdruck",
        Overweight: "Übergewicht",
        Elevated_blood: "erhöhte Blutfettwerte (wie z.B. Cholesterin)",
        Vascular_diseases: "Gefässerkrankungen (z.B. Arteriosklerose)",
        Hypothyroidism: "Schilddrüsenunter- oder Überfunktion",
        Food_intolerances: "Nahrungsmittel-Intoleranzen",
        Fructose: "Fruchtzuckerunverträglichkeit",
        Gluten_intolerance: "Glutenintoleranz/Zöliakie",
        Lactose_intolerance: "Milchzuckerunverträglichkeit",
        IgG_mediated: "IgG-vermittelte Intoleranzen",
        IgE_mediated: "IgE-vermittelte Allergien",
        Histamine: "Histamin",
        FODMAPs: "FODMAPs",
        Food_allergies: "Nahrungsmittelallergien",
        Peanuts: "Erdnüsse",
        Celery: "Sellerie",
        Crustaceans: "Krebstiere wie z.B. Krebse, Shrimps etc.",
        Lupin: "Lupine",
        hazelnuts: "Schalenfrüchte wie z.B. Mandeln, Haselnüsse etc.",
        Sulphur: "Schwefeldioxid/Sulfide wie z.B. Trockenobst",
        mustard: "Senf",
        sesame: "Sesam",
        soya: "Soja",
        mussels: "Weichtiere wie z.B. Muscheln",
        None_of_the_above: "Keiner der oben genannten Punkte",
        Alkaline_fasting: "Basenfasten",
        Low_Carb: "Low-Carb",
        FODMAP: "FODMAP",
        Intermittent: "Intervallfasten",
        Mixed_diet: "Mischkost (Alles-Esser)",
        Wholefood: "Vollwerternährung",
        Flexitarians: "Flexitarier",
        vegetarian_options:
          "Welche dieser vegetarischen Ernährungsformen trifft auf dich zu?",
        Pescetarisch: "Pescetarisch (mit Fisch)",
        Lakto_Vegetarisch: "Lakto-Vegetarisch (mit Milchprodukte)",
        Ovo_Vegetarisch: "Ovo-Vegetarisch (mit Eiern)",
        Ovo_Lakto_Vegetarisch:
          "Ovo-Lakto-Vegetarisch (mit Eiern und Milchprodukten)",
        drink_alcohol: "Wie häufig pro Woche trinkst du Alkohol?",
        Nie: "Nie",
        _2_Mal_pro_Woche: "1-2 Gläser",
        _4_Mal_pro_Woche: "2-4 Gläser",
        _6_Mal_pro_Woche: "4-6 Gläser",
        Jeden_Tag: "Jeden Tag",
        alcohol_type: "Welche Art von Alkohol trinkst du?",
        Wein: "Wein",
        Beer: "Bier",
        Spirits: "Spirituosen",
        Cocktails: "Cocktails",
        how_much_alcohol: "Wie viel Alkohol trinkst du pro Tag?",
        _2_servings: "1-2 Standardgläser",
        _3_servings: "2-3 Standardgläser",
        _4_servings: "3-4 Standardgläser",
        More: "Mehr als 4 Standardgläser",
        handvoll:
          "1 Portion = 1 Handvoll z.B. Beeren, 2-3 Aprikosen, 1-2 Mandarinen",
        handvoll_veg: "1 Portion = 1 Handvoll",
        reference_text:
          "1 Standardglas = 3 dl Bier, 1 dl Wein, 1 Aperitif (4 cl) oder 1 Schnaps (3 cl)",
        pay_now:
          "Sichere und schnelle Bezahlung für deine Gesundheitsauswertung.",
        enter_details:
          "Starte sofort mit deiner individuellen Analyse – gib einfach deine Zahlungsdaten ein und leg los! Deine Daten sind bei uns sicher und werden vertraulich behandelt.",
        total: "Insgesamt",
        pay_now_btn: "Jetzt bezahlen",
        guaranteed:
          "Garantiert sicher, so dass alle Transaktionen mit der höchsten Sicherheitsstufe geschützt sind.",
        card_number: "Kartennummer",
        expiration: "Gültigkeitsdatum",
        coupon: "Hast du einen Coupon-Code?",
        payment_head: "Deine Zahlung war erfolgreich – Danke!",
        payment_para:
          "Vielen Dank, dass du deine Zahlung abgeschlossen hast! Wir schätzen dein Vertrauen in uns sehr. Ein detaillierter Bericht mit einer umfassenden Zusammenfassung deiner Ergebnisse wird dir direkt per E-Mail zugesandt.Solltest du Fragen haben oder weitere Unterstützung benötigen, zögere bitte nicht, uns zu kontaktieren. Wir sind für dich da und begleiten dich bei jedem Schritt. Nochmals vielen Dank – wir freuen uns darauf, dich zu unterstützen!",
        payment_btn: "Zurück zu Startseite",
        please_select: "Bitte wähle mindestens eine Präferenz aus.",
        which_oils:
          "Welche Öle oder Fette brauchst du für die Zubereitung deiner Mahlzeiten?",
        cold_dishes:
          "Für die kalte Küche z.B. Salatsauce; für die warme Küche z.B. zum Anbraten oder als Streichfett",
        None: "gar nicht",
        Butter: "Butter",
        Margarine: "Margarine",
        coconut_oil: "Anderes z.B. natives Kokosöl ",
        Rapeseed_Oil: "Rapsöl",
        Olive_Oil: "Olivenöl",
        Walnut_Oil: "Baumnussöl",
        Flaxseed_Oil: "Leinöl",
        Hemp_Oil: "Hanföl",
        Wheat_Germ: "Weizenkeimöl",
        Soybean: "Sojaöl",
        sunflower: "Andere wie z.B. Sonnenblumenöl",
        Cooking_Butter: "Bratbutter",
        Coconut_Oil: "Kokosöl",
        Sunflower_Oil: "Sonnenblumenöl",
        Peanut_Oil: "Erdnussöl ",
        Other: "Andere",
        Frying_Oils: "Frittieröle wie z.B. ein HOLL Rapsöl oder Sonnenblumenöl",
        Streichfett: "Streichfett",
        kalte: "kalte Küche (Salatsauce)",
        warme: "warme Küche z.B. zum Anbraten, Dünsten",
        Frittieren: "Zum Frittieren",
        processing_payment: "Zahlung wird bearbeitet",
        Current_Weight: "Aktuelles Gewicht eingeben",
        Target_Weight: "Zielgewicht eingeben",
        Enter_Email: "Gib deine E-Mail-Adresse ein.",
        invalid_promotion_code:
          "Der eingegebene Rabattcode ist ungültig oder inaktiv.",
        payment_error_generic:
          "Es ist ein Fehler bei der Zahlungsabwicklung aufgetreten.",
        payment_details_error:
          "Fehler beim Abrufen der Zahlungsdetails. Bitte versuche es erneut.",
        loading: "Laden...",
        select_options: "Auswählen",
        no_allergies: "Keine Allergien",
        Rapsl: "HOLL Rapsöl",
        Sonnenblumenl: "HO-Sonnenblumenöl",
        harmonal: "Hormonelle Dysfunktionen",
        pms: "PMS",
        Menopause: "Menopause",
        zero_portions: "0 Portionen",
        one_to_two_portions: "1-2 Portionen",
        three_to_four_portions: "3-4 Portionen",
        five_or_more_portions: "5 oder mehr Portionen",
        how_often_eat_meat: "Wie häufig pro Woche konsumierst du Fleisch?",
        Like: "Gefällt mir",
        Dislike: "Gefällt mir nicht",
        meal_plan_dislike:
          "Welche Lebensmittel isst du gerne? Gibt es etwas, das du nicht magst ?",
        offers: "Angebote",
        references: "Referenzen",
      },
    },
    fr: {
      translation: {
        Address: "Adresse fr",
        Enter_Email: "Entrez votre e-mail.",
        back: "Retour",
        invalid_promotion_code:
          "Le code promotionnel que vous avez saisi est invalide ou inactif.",
        payment_error_generic:
          "Une erreur est survenue lors du traitement de votre paiement.",
        payment_details_error:
          "Échec de la récupération des détails de paiement. Veuillez réessayer.",
        loading: "Chargement...",
        select_options: "Sélectionner",
        no_allergies: "Pas d'allergies",
        Like: "J'aime",
        Dislike: "Je n'aime pas",
        meal_plan_dislike:
          "Sélectionnez les repas que vous aimez ou n'aimez pas",
      },
    },
  },
  keySeparator: false,
  interpolation: { escapeValue: false },
});

export default i18n;
