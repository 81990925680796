import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/images/arrow.svg";

const PreferenceButton = ({ preference, isSelected, onClick }) => (
  <button
    onClick={() => onClick(preference)}
    className={isSelected ? "selected btn btn-default" : "btn btn-default"}
  >
    {preference}
  </button>
);

PreferenceButton.propTypes = {
  preference: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const OilsAndFats = ({ onSelectionComplete, onNextSlide }) => {
  const [selectedPreferences, setSelectedPreferences] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const savedPreferences =
      JSON.parse(localStorage.getItem("selectedPreferences")) || {};
    setSelectedPreferences(savedPreferences);
  }, []);

  const handlePreferenceSelect = useCallback((category, preference) => {
    setSelectedPreferences((prev) => {
      const updatedCategorySelection = prev[category] || [];
      const isSelected = updatedCategorySelection.includes(preference);

      if (isSelected) {
        return {
          ...prev,
          [category]: updatedCategorySelection.filter(
            (item) => item !== preference
          ),
        };
      } else {
        return {
          ...prev,
          [category]: [...updatedCategorySelection, preference],
        };
      }
    });
  }, []);

  const handleNextClick = () => {
    const allSelectedPreferences = Object.values(selectedPreferences).flat();

    localStorage.setItem(
      "selectedPreferences",
      JSON.stringify(allSelectedPreferences)
    );

    if (allSelectedPreferences.length > 0) {
      onSelectionComplete(allSelectedPreferences);
      onNextSlide();
    } else {
      alert(t("please_select"));
    }
  };

  const categories = [
    {
      title: t("Streichfett"),
      examples: [t("None"), t("Butter"), t("Margarine"), t("coconut_oil")],
    },
    {
      title: t("kalte"),
      examples: [
        t("Rapeseed_Oil"),
        t("Olive_Oil"),
        t("Walnut_Oil"),
        t("Flaxseed_Oil"),
        t("Hemp_Oil"),
        t("Wheat_Germ"),
        t("Soybean"),
        t("sunflower"),
      ],
    },
    {
      title: t("warme"),
      examples: [
        t("Olive_Oil"),
        t("Cooking_Butter"),
        t("Coconut_Oil"),
        t("Sunflower_Oil"),
        t("Peanut_Oil"),
        t("Rapsl"),
        t("Sonnenblumenl"),
        t("Other"),
      ],
    },
    {
      title: t("Frittieren"),
      examples: [
        t("Frying_Oils"),
        t("Peanut_Oil"),
        t("Sunflower_Oil"),
        t("Coconut_Oil"),
        t("Other"),
      ],
    },
  ];

  return (
    <div className="meal-plan-preferences">
      <h3>{t("which_oils")}</h3>
      <p>{t("cold_dishes")}</p>

      {categories.map((category) => (
        <div key={category.title}>
          <h4>{category.title}</h4>
          <div className="preference-options">
            {category.examples.map((example) => (
              <PreferenceButton
                key={example}
                preference={example}
                isSelected={selectedPreferences[category.title]?.includes(
                  example
                )}
                onClick={() => handlePreferenceSelect(category.title, example)}
              />
            ))}
          </div>
        </div>
      ))}
      <button
        onClick={handleNextClick}
        className="btn btn-default start-button"
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

OilsAndFats.propTypes = {
  onSelectionComplete: PropTypes.func.isRequired,
  onNextSlide: PropTypes.func.isRequired,
};

export default OilsAndFats;
