import React, { useState, useEffect } from "react";
import "../../assets/sass/_nutricheck.scss";
import { Slider } from "antd";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";

const AgeSelection = ({ onAgeSelect, onNextSlide }) => {
  const [age, setAge] = useState(18); // Default age
  const [ageSelected, setAgeSelected] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Load saved age from localStorage
    const savedAge = localStorage.getItem("age");
    if (savedAge) {
      setAge(Number(savedAge));
      setAgeSelected(true); // Mark the age as selected
    }
  }, []);

  const handleAgeChange = (value) => {
    setAge(value);
    setAgeSelected(true);
    localStorage.setItem("age", value); // Save age to localStorage
    onAgeSelect(value);
  };

  return (
    <div className="age-selection">
      <h3>{t("select_age")}</h3>
      <p>{t("select_age_p")}</p>
      <Slider min={1} max={100} value={age} onChange={handleAgeChange} />
      <button
        className="start-button btn btn-default"
        onClick={onNextSlide}
        disabled={!ageSelected}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default AgeSelection;
