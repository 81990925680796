import React from "react";
import "../../assets/sass/_nutricheck.scss";
import Slim from "../../assets/images/SLIM_SHREDDED_BODY.png";
import Average from "../../assets/images/HERO.png";
import Heavy from "../../assets/images/BEACH_BODY.png";
import SlimFemale from "../../assets/images/slim-female.png";
import AverageFemale from "../../assets/images/average-female.png";
// import HeavyFemale from "../../assets/images/heavy-female.png";
import { useTranslation } from "react-i18next";

const GoalOptions = ({ onGoalOptionSelect, onNextSlide }) => {
  const { t } = useTranslation();
  const selectedGender = localStorage.getItem("gender");
  const images =
    selectedGender === "female"
      ? {
          slim: SlimFemale,
          average: AverageFemale,
          heavy: SlimFemale,
        }
      : {
          slim: Slim,
          average: Average,
          heavy: Heavy,
        };
  return (
    <div className="goal-selection">
      <h3>{t("select_goal")}</h3>
      <div className="goal-options">
        <button
          className="btn btn-default"
          onClick={() => {
            onGoalOptionSelect("Lose Weight");
            onNextSlide();
          }}
        >
          <img src={images.slim} alt="slim-img" />
          <div>{t("lose_weight")}</div>
        </button>
        <button
          className="btn btn-default"
          onClick={() => {
            onGoalOptionSelect("Gain Muscle");
            onNextSlide();
          }}
        >
          <img src={images.average} alt="slim-img" />
          <div>{t("gain_muscle")}</div>
        </button>
        <button
          className="btn btn-default"
          onClick={() => {
            onGoalOptionSelect("Get Shredded");
            onNextSlide();
          }}
        >
          <img src={images.heavy} alt="slim-img" />
          <div>{t("get_shredded")}</div>
        </button>
      </div>
    </div>
  );
};

export default GoalOptions;
