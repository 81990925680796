import React, { useState, useEffect } from "react";
import "../../assets/sass/_nutricheck.scss";
import AgeSelection from "./AgeSelection";
import Male from "../../assets/images/male.svg";
import Female from "../../assets/images/female.svg";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

const SlideContent = ({
  slideIndex,
  onGenderSelect,
  onAgeSelect,
  onNextSlide,
}) => {
  const [selectedGender, setSelectedGender] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Initialize selectedGender from localStorage
    const savedGender = localStorage.getItem("gender");
    if (savedGender) {
      setSelectedGender(savedGender);
    }
  }, []);

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    localStorage.setItem("gender", gender);
    onGenderSelect(gender);
    onNextSlide();
  };

  return (
    <div className="slide-content">
      {slideIndex === 0 ? (
        <div className="gender-selection">
          <h3>{t("choose_gender")}</h3>
          <button
            onClick={() => handleGenderSelect("male")}
            className={selectedGender === "male" ? "selected" : ""}
          >
            <ReactSVG src={Male} alt="male-icon" />
          </button>
          <button
            onClick={() => handleGenderSelect("female")}
            className={selectedGender === "female" ? "selected" : ""}
          >
            <ReactSVG src={Female} alt="female-icon" />
          </button>
        </div>
      ) : slideIndex === 1 ? (
        <AgeSelection onAgeSelect={onAgeSelect} onNextSlide={onNextSlide} />
      ) : (
        <h2>Slide {slideIndex + 1}</h2>
      )}
    </div>
  );
};

export default SlideContent;
