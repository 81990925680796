import React from "react";
import BannerImg from "../../assets/images/banner-image.png";
import ReviewsImg from "../../assets/images/reviews.png";
import { Link } from "react-router-dom";
import "../../assets/sass/_banner.scss";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <div className="banner">
      <img className="img-fluid" src={BannerImg} alt="banner-img" />
      <div className="container">
        <div className="banner-cont">
          {/* <img className="img-fluid" src={ReviewsImg} alt="reviews-img" /> */}
          <h3>{t("qualified_results")}</h3>
          <h2>{t("Transform_Heading")}</h2>
          <h1>{t("Personalized")}</h1>
          <h4>{t("Welcome")}</h4>
          <p>{t("Certified")}</p>
          <div class="banner-btn">
            <Link to="/nutricheck">{t("lets_go")}</Link>
            <Link to="/nutricheck">{t("choose_individual")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
