// import React, { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
// import Arrow from "../../assets/images/arrow.svg";
// import axios from "axios";
// const MealPlanPreferences = ({ onPreferenceSelect, onNextSlide }) => {
//   const [selectedPreferences, setSelectedPreferences] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [subcategories, setSubcategories] = useState({}); // Object to store subcategories by category ID
//   const [isLoading, setIsLoading] = useState(true); // Loading state
//   const { t } = useTranslation();

//   // Fetch categories and subcategories on component mount
//   useEffect(() => {
//     axios
//       .get("https://ntn.codefest.io/api/site/categories")
//       .then((response) => {
//         if (response.data.status === 200) {
//           const fetchedCategories = response.data.data;
//           setCategories(fetchedCategories);

//           // Fetch subcategories for each category automatically
//           fetchedCategories.forEach((category) => {
//             fetchSubcategories(category.id);
//           });

//           // Set loading to false once categories and subcategories are fetched
//           setIsLoading(false);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching categories:", error);
//         setIsLoading(false); // Set loading to false in case of an error
//       });
//   }, []);

//   const handlePreferenceSelect = (preference) => {
//     setSelectedPreferences((prev) => {
//       if (prev.includes(preference)) {
//         return prev.filter((item) => item !== preference);
//       } else {
//         return [...prev, preference];
//       }
//     });
//   };

//   const handleNextClick = () => {
//     if (selectedPreferences.length > 0) {
//       onPreferenceSelect(selectedPreferences);
//       onNextSlide();
//     } else {
//       alert(t("please_select"));
//     }
//   };

//   // Fetch subcategories for a specific category
//   const fetchSubcategories = (categoryId) => {
//     if (!subcategories[categoryId]) {
//       // Only fetch subcategories if they are not already fetched
//       axios
//         .get(`https://ntn.codefest.io/api/site/subcategories/${categoryId}`)
//         .then((response) => {
//           if (response.data.status === 200) {
//             setSubcategories((prev) => ({
//               ...prev,
//               [categoryId]: response.data.data, // Cache subcategories for this category
//             }));
//           }
//         })
//         .catch((error) => {
//           console.error(
//             `Error fetching subcategories for category ${categoryId}:`,
//             error
//           );
//         });
//     }
//   };

//   // Loader component
//   const Loader = () => (
//     <div
//       style={{
//         textAlign: "center",
//         fontSize: "1.5rem",
//         // color: "#888",
//         padding: "20px",
//       }}
//     >
//       <p>Loading...</p>
//       {/* You can replace this with a spinner or other animation if preferred */}
//     </div>
//   );

//   // const veggies = [
//   //   t("Broccoli"),
//   //   t("Cauliflower"),
//   //   t("Onion"),
//   //   t("Bell_Pepper"),
//   //   t("Eggplant"),
//   //   t("Cabbage"),
//   //   t("Asparagus"),
//   //   t("Spinach"),
//   //   t("Cucumber"),
//   //   t("Tomato"),
//   // ];

//   // const grains = [
//   //   t("Rice"),
//   //   t("Quinoa"),
//   //   t("Couscous"),
//   //   t("Buckwheat"),
//   //   t("Millet_Groats"),
//   //   t("Amaranth"),
//   //   t("Semoline"),
//   //   t("Cornmeal"),
//   //   t("Bulgur"),
//   // ];

//   // const Ingredients = [
//   //   t("Avocado"),
//   //   t("Beans"),
//   //   t("Eggs"),
//   //   t("Mushrooms"),
//   //   t("Cottage_cheese"),
//   //   t("Milk"),
//   //   t("Tofu"),
//   //   t("Hummus"),
//   //   t("Plant_milk"),
//   //   t("Protein_powder"),
//   //   t("Vegan_protein_powder"),
//   // ];

//   // const Meat = [
//   //   t("Turkey"),
//   //   t("Beef"),
//   //   t("Chicken"),
//   //   t("Seafood"),
//   //   t("Pork"),
//   //   t("Milk"),
//   //   t("Tofu"),
//   //   t("Fish"),
//   // ];

//   // const Fruits = [
//   //   t("Citrus"),
//   //   t("Apple"),
//   //   t("Pear"),
//   //   t("Kiwi"),
//   //   t("Bananas"),
//   //   t("Persimmon"),
//   //   t("Peach"),
//   //   t("Berries"),
//   //   t("Grape"),
//   //   t("Pomegranate"),
//   // ];

//   return (
//     <div className="meal-plan-preferences">
//       {isLoading ? (
//         <Loader />
//       ) : (
//         <>
//           <h3>{t("meal_plan")}</h3>
//           {/* <h4>{t("veggies")}</h4> */}
//           {categories.map((category) => (
//             <div key={category.id}>
//               <h4>{category.name}</h4>

//               {/* Render subcategories if they are already fetched */}
//               {subcategories[category.id] && (
//                 <div className="preference-options">
//                   {subcategories[category.id].map((subcategory) => (
//                     <button
//                       key={subcategory.id}
//                       onClick={() => handlePreferenceSelect(subcategory.name)}
//                       className={
//                         selectedPreferences.includes(subcategory.name)
//                           ? "selected btn btn-default"
//                           : "btn btn-default"
//                       }
//                     >
//                       {subcategory.name}
//                     </button>
//                   ))}
//                 </div>
//               )}
//             </div>
//           ))}
//           {/* <div className="preference-options">
//         {veggies.map((preference) => (
//           <button
//             key={preference}
//             onClick={() => handlePreferenceSelect(preference)}
//             className={
//               selectedPreferences.includes(preference)
//                 ? "selected btn btn-default"
//                 : "btn btn-default"
//             }
//           >
//             {preference}
//           </button>
//         ))}
//       </div>
//       <h4>{t("grains")}</h4>
//       <div className="preference-options">
//         {grains.map((preference) => (
//           <button
//             key={preference}
//             onClick={() => handlePreferenceSelect(preference)}
//             className={
//               selectedPreferences.includes(preference)
//                 ? "selected btn btn-default"
//                 : "btn btn-default"
//             }
//           >
//             {preference}
//           </button>
//         ))}
//       </div>
//       <h4>{t("ingredients")}</h4>
//       <div className="preference-options">
//         {Ingredients.map((preference) => (
//           <button
//             key={preference}
//             onClick={() => handlePreferenceSelect(preference)}
//             className={
//               selectedPreferences.includes(preference)
//                 ? "selected btn btn-default"
//                 : "btn btn-default"
//             }
//           >
//             {preference}
//           </button>
//         ))}
//       </div>
//       <h4>{t("meat_fish")}</h4>
//       <div className="preference-options">
//         {Meat.map((preference) => (
//           <button
//             key={preference}
//             onClick={() => handlePreferenceSelect(preference)}
//             className={
//               selectedPreferences.includes(preference)
//                 ? "selected btn btn-default"
//                 : "btn btn-default"
//             }
//           >
//             {preference}
//           </button>
//         ))}
//       </div>
//       <h4>{t("fruits")}</h4>
//       <div className="preference-options">
//         {Fruits.map((preference) => (
//           <button
//             key={preference}
//             onClick={() => handlePreferenceSelect(preference)}
//             className={
//               selectedPreferences.includes(preference)
//                 ? "selected btn btn-default"
//                 : "btn btn-default"
//             }
//           >
//             {preference}
//           </button>
//         ))}
//       </div> */}
//           <button
//             onClick={handleNextClick}
//             className="btn btn-default start-button"
//           >
//             {t("next_btn")}
//             <img className="img-fluid" src={Arrow} alt="arrow" />
//           </button>
//         </>
//       )}
//     </div>
//   );
// };

// export default MealPlanPreferences;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Arrow from "../../assets/images/arrow.svg";
import Chevron from "../../assets/images/chevron-down.svg";

const MealPlanPreferences = ({
  vegetarianOption = true,
  onPreferenceSelect,
  onNextSlide,
}) => {
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const getSelectedLanguage = () => {
    const lang = localStorage.getItem("lang") || "en";
    return { gm: "german", fr: "french", en: "english" }[lang] || "english";
  };

  useEffect(() => {
    console.log("Vegetarian Option:", vegetarianOption);

    // Retrieve the userData from localStorage
    const userData = JSON.parse(localStorage.getItem("userData"));

    // Load saved preferences if available
    if (
      userData &&
      userData["Let Us Create a Meal Plan Based on Your Preferences"]
    ) {
      setSelectedPreferences(
        userData["Let Us Create a Meal Plan Based on Your Preferences"]
      );
    }

    let isVegetarian = false;
    if (userData && userData["Do You Stick to One of These Diets?"]) {
      const dietValue = userData["Do You Stick to One of These Diets?"];
      isVegetarian =
        (typeof dietValue === "string" &&
          dietValue.toLowerCase().includes("vegetarian")) ||
        (Array.isArray(dietValue) &&
          dietValue.some(
            (diet) =>
              diet.toLowerCase().includes("vegetarian") ||
              diet.toLowerCase().includes("vegetarisch")
          ));
    }

    axios
      .get("https://codefest.io/api/site/categories")
      .then((response) => {
        if (response.data.status === 200) {
          const filteredCategories = response.data.data.filter((category) => {
            try {
              const parsedName = JSON.parse(category.name || "{}");
              const categoryName =
                parsedName[getSelectedLanguage()] || parsedName.english;

              if (isVegetarian) {
                const excludedCategories = [
                  "meat & fish",
                  "fleisch & fisch",
                  "viande et poisson",
                ];

                return !excludedCategories.some((excludedCategory) =>
                  categoryName
                    .toLowerCase()
                    .includes(excludedCategory.toLowerCase())
                );
              }

              return true;
            } catch (error) {
              console.error("Error parsing category name:", error);
              return true;
            }
          });

          setCategories(filteredCategories);

          filteredCategories.forEach((category) =>
            fetchSubcategories(category.id)
          );

          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setIsLoading(false);
      });
  }, [vegetarianOption]);

  const fetchSubcategories = (categoryId) => {
    axios
      .get(`https://codefest.io/api/site/subcategories/${categoryId}`)
      .then((response) => {
        if (response.data.status === 200) {
          setSubcategories((prev) => ({
            ...prev,
            [categoryId]: response.data.data,
          }));
        }
      })
      .catch((error) =>
        console.error(
          `Error fetching subcategories for category ${categoryId}:`,
          error
        )
      );
  };

  const handlePreferenceToggle = (preference) => {
    setSelectedPreferences((prev) =>
      prev.includes(preference)
        ? prev.filter((item) => item !== preference)
        : [...prev, preference]
    );
  };

  const handleNextClick = () => {
    if (selectedPreferences.length > 0) {
      onPreferenceSelect(selectedPreferences);
      onNextSlide();
    } else {
      alert(t("please_select"));
    }
  };

  const parseMultilingualName = (name) => {
    try {
      const parsedName = JSON.parse(name);
      return parsedName[getSelectedLanguage()] || parsedName.english;
    } catch {
      return name;
    }
  };

  const Loader = () => (
    <div style={{ textAlign: "center", fontSize: "1.5rem", padding: "20px" }}>
      <p>{t("loading")}</p>
    </div>
  );

  return (
    <div className="meal-plan-preferences">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h3>{t("meal_plan")}</h3>
          <div id="accordion">
            {categories.map((category) => (
              <div key={category.id} className="card custom-card">
                <div
                  className="card-header custom-card-header"
                  id={`heading${category.id}`}
                >
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target={`#collapse${category.id}`}
                      aria-expanded="true"
                      aria-controls={`collapse${category.id}`}
                    >
                      {parseMultilingualName(category.name)}
                      <img
                        className="chevron-down"
                        src={Chevron}
                        alt="chevron-down"
                      />
                    </button>
                  </h5>
                </div>

                <div
                  id={`collapse${category.id}`}
                  className="collapse"
                  aria-labelledby={`heading${category.id}`}
                  data-parent="#accordion"
                >
                  <div className="card-body card-body-custom">
                    {subcategories[category.id] &&
                      subcategories[category.id].map((subcategory) => (
                        <button
                          key={subcategory.id}
                          onClick={() =>
                            handlePreferenceToggle(
                              parseMultilingualName(subcategory.name)
                            )
                          }
                          className={
                            selectedPreferences.includes(
                              parseMultilingualName(subcategory.name)
                            )
                              ? "selected btn btn-primary"
                              : "btn btn-secondary"
                          }
                        >
                          {parseMultilingualName(subcategory.name)}
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handleNextClick}
            className="btn btn-default start-button"
          >
            {t("next_btn")}
            <img className="img-fluid" src={Arrow} alt="arrow" />
          </button>
        </>
      )}
    </div>
  );
};

export default MealPlanPreferences;
