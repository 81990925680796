import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Arrow from "../../assets/images/arrow.svg";
import Chevron from "../../assets/images/chevron-down.svg";

const MealLikeDislike = ({
  vegetarianOption = true,
  onMealPreferenceSelect,
  onNextSlide,
}) => {
  const [likedMeals, setLikedMeals] = useState([]);
  const [dislikedMeals, setDislikedMeals] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const getSelectedLanguage = () => {
    const lang = localStorage.getItem("lang") || "en";
    return { gm: "german", fr: "french", en: "english" }[lang] || "english";
  };

  useEffect(() => {
    // Restore meals from localStorage
    const storedPreferences = JSON.parse(
      localStorage.getItem("mealPreferences")
    ) || {
      likedMeals: [],
      dislikedMeals: [],
    };

    setLikedMeals(storedPreferences.likedMeals);
    setDislikedMeals(storedPreferences.dislikedMeals);

    const userData = JSON.parse(localStorage.getItem("userData"));
    let isVegetarian = false;
    if (userData && userData["Do You Stick to One of These Diets?"]) {
      const dietValue = userData["Do You Stick to One of These Diets?"];
      isVegetarian =
        (typeof dietValue === "string" &&
          dietValue.toLowerCase().includes("vegetarian")) ||
        (Array.isArray(dietValue) &&
          dietValue.some(
            (diet) =>
              diet.toLowerCase().includes("vegetarian") ||
              diet.toLowerCase().includes("vegetarisch")
          ));
    }

    axios
      .get("https://codefest.io/api/site/categories")
      .then((response) => {
        if (response.data.status === 200) {
          const filteredCategories = response.data.data.filter((category) => {
            try {
              const parsedName = JSON.parse(category.name || "{}");
              const categoryName =
                parsedName[getSelectedLanguage()] || parsedName.english;

              if (isVegetarian) {
                const excludedCategories = [
                  "meat & fish",
                  "fleisch & fisch",
                  "viande et poisson",
                ];
                const isExcluded = excludedCategories.some((excludedCategory) =>
                  categoryName
                    .toLowerCase()
                    .includes(excludedCategory.toLowerCase())
                );
                return !isExcluded;
              }

              return true;
            } catch (error) {
              console.error("Error parsing category name:", error);
              return true;
            }
          });

          setCategories(filteredCategories);
          filteredCategories.forEach((category) =>
            fetchSubcategories(category.id)
          );

          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setIsLoading(false);
      });
  }, [vegetarianOption]);

  const fetchSubcategories = (categoryId) => {
    axios
      .get(`https://codefest.io/api/site/subcategories/${categoryId}`)
      .then((response) => {
        if (response.data.status === 200) {
          setSubcategories((prev) => ({
            ...prev,
            [categoryId]: response.data.data,
          }));
        }
      })
      .catch((error) =>
        console.error(
          `Error fetching subcategories for category ${categoryId}:`,
          error
        )
      );
  };

  const updateLocalStorage = (newLikedMeals, newDislikedMeals) => {
    localStorage.setItem(
      "mealPreferences",
      JSON.stringify({
        likedMeals: newLikedMeals,
        dislikedMeals: newDislikedMeals,
      })
    );
  };

  const handleLikeToggle = (meal) => {
    const newLikedMeals = likedMeals.includes(meal)
      ? likedMeals.filter((item) => item !== meal)
      : [...likedMeals, meal];

    if (dislikedMeals.includes(meal)) {
      const newDislikedMeals = dislikedMeals.filter((item) => item !== meal);
      setDislikedMeals(newDislikedMeals);
    }

    setLikedMeals(newLikedMeals);
    updateLocalStorage(newLikedMeals, dislikedMeals);
  };

  const handleDislikeToggle = (meal) => {
    const newDislikedMeals = dislikedMeals.includes(meal)
      ? dislikedMeals.filter((item) => item !== meal)
      : [...dislikedMeals, meal];

    if (likedMeals.includes(meal)) {
      const newLikedMeals = likedMeals.filter((item) => item !== meal);
      setLikedMeals(newLikedMeals);
    }

    setDislikedMeals(newDislikedMeals);
    updateLocalStorage(likedMeals, newDislikedMeals);
  };

  const handleNextClick = () => {
    if (likedMeals.length > 0 || dislikedMeals.length > 0) {
      onMealPreferenceSelect({ likedMeals, dislikedMeals });
      onNextSlide();
    } else {
      alert(t("please_select"));
    }
  };

  const parseMultilingualName = (name) => {
    try {
      const parsedName = JSON.parse(name);
      return parsedName[getSelectedLanguage()] || parsedName.english;
    } catch {
      return name;
    }
  };

  const Loader = () => (
    <div style={{ textAlign: "center", fontSize: "1.5rem", padding: "20px" }}>
      <p>{t("loading")}</p>
    </div>
  );

  return (
    <div className="meal-plan-preferences">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h3>{t("meal_plan_dislike")}</h3>
          <div id="accordion">
            {categories.map((category) => (
              <div key={category.id} className="card custom-card">
                <div
                  className="card-header custom-card-header"
                  id={`heading${category.id}`}
                >
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target={`#collapse${category.id}`}
                      aria-expanded="true"
                      aria-controls={`collapse${category.id}`}
                    >
                      {parseMultilingualName(category.name)}
                      <img
                        className="chevron-down"
                        src={Chevron}
                        alt="chevron-down"
                      />
                    </button>
                  </h5>
                </div>

                <div
                  id={`collapse${category.id}`}
                  className="collapse"
                  aria-labelledby={`heading${category.id}`}
                  data-parent="#accordion"
                >
                  <div className="card-body card-body-custom">
                    {subcategories[category.id] &&
                      subcategories[category.id].map((subcategory) => (
                        <div key={subcategory.id} className="meal-selection">
                          <p>{parseMultilingualName(subcategory.name)}</p>
                          <div>
                            <button
                              onClick={() =>
                                handleLikeToggle(
                                  parseMultilingualName(subcategory.name)
                                )
                              }
                              className={
                                likedMeals.includes(
                                  parseMultilingualName(subcategory.name)
                                )
                                  ? "selected btn btn-success"
                                  : "btn btn-secondary"
                              }
                            >
                              {t("Like")}
                            </button>
                            <button
                              onClick={() =>
                                handleDislikeToggle(
                                  parseMultilingualName(subcategory.name)
                                )
                              }
                              className={
                                dislikedMeals.includes(
                                  parseMultilingualName(subcategory.name)
                                )
                                  ? "selected btn btn-danger"
                                  : "btn btn-secondary"
                              }
                            >
                              {t("Dislike")}
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handleNextClick}
            className="btn btn-default start-button"
          >
            {t("next_btn")}
            <img className="img-fluid" src={Arrow} alt="arrow" />
          </button>
        </>
      )}
    </div>
  );
};

export default MealLikeDislike;
