import { t } from "i18next";
import React, { useState, useEffect } from "react";

const EmailInput = ({ email, onEmailSelect, onNextSlide }) => {
  const [localEmail, setLocalEmail] = useState(email);

  useEffect(() => {
    setLocalEmail(email);
  }, [email]);

  const handleEmailChange = (e) => {
    setLocalEmail(e.target.value);
  };

  const handleNext = () => {
    onEmailSelect(localEmail);
    onNextSlide();
  };

  return (
    <div className="slide-content">
      <h2>{t("Enter_Email")}</h2>
      <input
        type="email"
        value={localEmail}
        onChange={handleEmailChange}
        placeholder={t("Enter_Email")}
        className="form-control"
      />
      <button
        className="btn btn-default start-button"
        onClick={handleNext}
        disabled={!localEmail}
      >
        Next
      </button>
    </div>
  );
};

export default EmailInput;
