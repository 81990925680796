import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const WaterIntakeSelection = ({ onWaterIntakeSelect, onNextSlide }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option.text);
    onWaterIntakeSelect(option.text);
    onNextSlide();
  };

  const { t } = useTranslation();

  return (
    <div className="water-intake-selection">
      <h3>{t("water_intake")}</h3>
      <div className="water-intake-buttons diet-options">
        {[
          // { text: t("coffee"), icon: "☕️" },
          {
            text: t("two_glasses"),
            icon: "💧",
            subtext: t("two_glasses_sec"),
          },
          {
            text: t("six_glasses"),
            icon: "💧💧",
            subtext: t("six_glasses_sec"),
          },
          { text: t("seven"), icon: "💧💧💧", subtext: t("seven_sec") },
          {
            text: t("ten_glasses"),
            icon: "🐳",
            subtext: t("ten_glasses_sec"),
          },
        ].map(({ text, icon, subtext }) => (
          <button
            key={text}
            onClick={() => handleOptionSelect({ text })}
            className={
              selectedOption === text
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            <div>
              {text} <span className="icon">{icon}</span>
            </div>
            {subtext && <div className="subtext">{subtext}</div>}
          </button>
        ))}
      </div>
    </div>
  );
};

export default WaterIntakeSelection;
