import React, { useState, useEffect } from "react";
import "../../assets/sass/_header.scss";
import i18n from "../Lang/Lang";
import { useTranslation } from "react-i18next";
import { Select, Button, Drawer } from "antd";

import Logo from "../../assets/images/nutriteam-logo.svg";
import Hamburger from "../../assets/images/hamburger.svg";
import { Link } from "react-router-dom";

const Header = (props) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const langChange = (value) => {
    console.log(`selected ${value}`);
    props.setLanguage(value);
    localStorage.setItem("lang", value);
    console.log("localstorage :>> ", localStorage.getItem("lang"));
    i18n.changeLanguage(value);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let lang = localStorage.getItem("lang");
    if (!lang) {
      lang = "gm";
      localStorage.setItem("lang", lang);
    }
    props.setLanguage(lang);
    i18n.changeLanguage(lang);
  }, [props]);

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="header-nav">
          {/* <h5>{t("Address")}</h5> */}
          <Link to="/">
            {" "}
            <img src={Logo} alt="logo" />
          </Link>
          <div>
            <Select
              defaultValue={props.language}
              style={{ width: 80 }}
              onChange={langChange}
            >
              <Option value="en">EN</Option>
              <Option value="gm">DE</Option>
              <Option value="fr">FR</Option>
            </Select>
            <button className="btn btn-default" onClick={showDrawer}>
              <img src={Hamburger} alt="hamburger" />
            </button>
            {/* Drawer */}
            <Drawer title="" onClose={onClose} open={open}>
              <Link to="https://www.nutriteam.ch/">{t("home")}</Link>
              <Link to="https://www.nutriteam.ch/angebote/">{t("offers")}</Link>
              <Link to="https://www.nutriteam.ch/referenzen/">
                {t("references")}
              </Link>
              <Link to="https://www.nutriteam.ch/team/">{t("about_us")}</Link>
              <Link>Faq</Link>
              {/* <Link>{t("offers")}</Link>
              <Link>{t("references")}</Link> */}
              <Link to="https://www.nutriteam.ch/unsere-blog-beitraege/">
                Blog
              </Link>
              {/* <Link>Nutricheck</Link> */}
            </Drawer>
            {/* Drawer */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
