import React, { useEffect, useState } from "react";
import "../../assets/sass/_nutricheck.scss";
import Slim from "../../assets/images/SLIM_BODY.png";
import Average from "../../assets/images/average.png";
import Heavy from "../../assets/images/heavy.png";
import SlimFemale from "../../assets/images/slim-female.png";
import AverageFemale from "../../assets/images/average-female.png";
import HeavyFemale from "../../assets/images/heavy-female.png";
import { useTranslation } from "react-i18next";

const GoalSelection = ({ onGoalSelect, onNextSlide }) => {
  const { t } = useTranslation();
  const selectedGender = localStorage.getItem("gender");
  const [selectedBodyType, setSelectedBodyType] = useState("");

  const images =
    selectedGender === "female"
      ? {
          slim: SlimFemale,
          average: AverageFemale,
          heavy: HeavyFemale,
        }
      : {
          slim: Slim,
          average: Average,
          heavy: Heavy,
        };

  // Get the saved body type from localStorage
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const bodyType = userData["Select Your Body Type?"];
    if (bodyType) {
      setSelectedBodyType(bodyType);
    }
  }, []);

  const handleGoalClick = (goal) => {
    setSelectedBodyType(goal);
    onGoalSelect(goal);

    // Save the selected body type to localStorage
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    userData["Select Your Body Type?"] = goal;
    localStorage.setItem("userData", JSON.stringify(userData));

    onNextSlide();
  };

  return (
    <div className="goal-selection">
      <h3>{t("select_body_type")}</h3>
      <div className="goal-options">
        <button
          className={`btn btn-default ${
            selectedBodyType === "slim" ? "selected" : ""
          }`}
          onClick={() => handleGoalClick("slim")}
        >
          <img src={images.slim} alt="slim-img" />
          <div>{t("slim")}</div>
        </button>
        <button
          className={`btn btn-default ${
            selectedBodyType === "average" ? "selected" : ""
          }`}
          onClick={() => handleGoalClick("average")}
        >
          <img src={images.average} alt="average-img" />
          <div>{t("average")}</div>
        </button>
        <button
          className={`btn btn-default ${
            selectedBodyType === "heavy" ? "selected" : ""
          }`}
          onClick={() => handleGoalClick("heavy")}
        >
          <img src={images.heavy} alt="heavy-img" />
          <div>{t("heavy")}</div>
        </button>
      </div>
    </div>
  );
};

export default GoalSelection;
